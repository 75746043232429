import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { SERVER_URL } from '@src/app/core/constants/apis-list.constant';
import { Observable } from 'rxjs';
import { HttpErrorResponse, HttpHandlerFn, HttpResponse } from '@angular/common/http';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Modify the request by appending the base URL
    const baseUrl = SERVER_URL; // Your base URL here
    const modifiedRequest = request.clone({ url: `${baseUrl}${request.url}` });

    // Process the response or handle errors if needed
    return next.handle(modifiedRequest);
  }
}

export const baseUrlInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  if (!request.url.startsWith('/assets') && !request.url.includes('s3.amazonaws') && !request.url.includes('amazonaws.com')) {
    // If not, clone the request and prepend the base URL
    const baseUrl = SERVER_URL; // Your base URL here
    const modifiedRequest = request.clone({ url: `${baseUrl}${request.url}` });
    return next(modifiedRequest);
  }
  return next(request);
};
