import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of } from 'rxjs';
import { CategoriesRepository } from '../categories.repository';
import * as CategoriesActions from './categories.actions';

@Injectable()
export class CategoriesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly categoriesRepository: CategoriesRepository,
) {}

  getProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoriesActions.getProducts),
      mergeMap(({ params }) =>
        this.categoriesRepository.getProducts(params).pipe(
          map((response) => CategoriesActions.getProductsSuccess({ products: response })),
          catchError((error) => of(CategoriesActions.getProductsFailure({ error: error.error.message })))
        )
      )
    )
  );

  loadCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoriesActions.loadCategories),
      mergeMap(() =>
        this.categoriesRepository.getCategories().pipe(
          map((categories) =>
            CategoriesActions.loadCategoriesSuccess({ categories })
          ),
          catchError((error) =>
            of(CategoriesActions.loadCategoriesFailure({ error }))
          )
        )
      )
    )
  );

  getSubcategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoriesActions.getSubcategories),
      mergeMap(({ categoryIds }) => {
        let params: HttpParams = new HttpParams({
          fromObject: {
            page: 1,
            limit: 100,
          },
        });

        if (categoryIds && categoryIds.length > 0) {
          params = params.set('filter.parentCategoryId', categoryIds.length === 1
            ? `$eq:${categoryIds[0]}`
            :`$in:${categoryIds.join(',')}`);
        }

        return this.categoriesRepository.getSubcategories(params).pipe(
          map((response) => CategoriesActions.getSubcategoriesSuccess({ subcategories: response.data })),
          catchError((error) => of(CategoriesActions.getSubcategoriesFailure({ error: error.error.message })))
        )
      })
    )
  );

  getBrands$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoriesActions.getBrands),
      mergeMap(({ categoryIds }) => {
        let params: HttpParams = new HttpParams({
          fromObject: {
            page: 1,
            limit: 100,
          },
        });

        if (categoryIds && categoryIds.length > 0) {
          params = params.set('filter.products.subcategories.parentCategory', categoryIds.length === 1
            ? `$eq:${categoryIds[0]}`
            :`$in:${categoryIds.join(',')}`);
        }

        return this.categoriesRepository.getBrands(params).pipe(
          map((response) => CategoriesActions.getBrandsSuccess({ brands: response.data })),
          catchError((error) => of(CategoriesActions.getBrandsFailure({ error: error.error.message })))
        )
      })
    )
  );

  getTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoriesActions.getTags),
      mergeMap(({ categoryIds }) => {
        let params: HttpParams = new HttpParams({
          fromObject: {
            page: 1,
            limit: 100,
          },
        });

        if (categoryIds && categoryIds.length > 0) {
          params = params.set('filter.products.subcategories.parentCategory', categoryIds.length === 1
            ? `$eq:${categoryIds[0]}`
            :`$in:${categoryIds.join(',')}`);
        }

        return this.categoriesRepository.getTags(params).pipe(
          map((response) => CategoriesActions.getTagsSuccess({ tags: response.data })),
          catchError((error) => of(CategoriesActions.getTagsFailure({ error: error.error.message })))
        )
      })
    )
  );

  getStyles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoriesActions.getStyles),
      mergeMap(({ categoryIds }) => {
        let params: HttpParams = new HttpParams({
          fromObject: {
            page: 1,
            limit: 100,
            'filter.type': '$eq:style',
          },
        });

        if (categoryIds && categoryIds.length > 0) {
          params = params.set('filter.products.subcategories.parentCategory', categoryIds.length === 1
            ? `$eq:${categoryIds[0]}`
            :`$in:${categoryIds.join(',')}`);
        }

        return this.categoriesRepository.getTags(params).pipe(
          map((response) => CategoriesActions.getStylesSuccess({ styles: response.data })),
          catchError((error) => of(CategoriesActions.getStylesFailure({ error: error.error.message })))
        )
      })
    )
  );

  getBaseMaterials$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoriesActions.getBaseMaterials),
      mergeMap(({ categoryIds }) => {
        let params: HttpParams = new HttpParams({
          fromObject: {
            page: 1,
            limit: 100,
            'filter.type': '$eq:base_material',
          },
        });

        if (categoryIds && categoryIds.length > 0) {
          params = params.set('filter.products.subcategories.parentCategory', categoryIds.length === 1
            ? `$eq:${categoryIds[0]}`
            :`$in:${categoryIds.join(',')}`);
        }

        return this.categoriesRepository.getTags(params).pipe(
          map((response) => CategoriesActions.getBaseMaterialsSuccess({ baseMaterials: response.data })),
          catchError((error) => of(CategoriesActions.getBaseMaterialsFailure({ error: error.error.message })))
        )
      })
    )
  );

  getFinishes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoriesActions.getFinishes),
      mergeMap(({ categoryIds }) => {
        let params: HttpParams = new HttpParams({
          fromObject: {
            page: 1,
            limit: 100,
            'filter.type': '$eq:finish',
          },
        });

        if (categoryIds && categoryIds.length > 0) {
          params = params.set('filter.products.subcategories.parentCategory', categoryIds.length === 1
            ? `$eq:${categoryIds[0]}`
            :`$in:${categoryIds.join(',')}`);
        }

        return this.categoriesRepository.getTags(params).pipe(
          map((response) => CategoriesActions.getFinishesSuccess({ finishes: response.data })),
          catchError((error) => of(CategoriesActions.getFinishesFailure({ error: error.error.message })))
        )
      })
    )
  );
}
