import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from './auth.state';

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const selectUser = createSelector(
  selectAuthState,
  (state: AuthState) => state.user
);

export const selectTokenVerified = createSelector(
  selectAuthState,
  (state: AuthState) => state.tokenVerified
);

export const selectRegisterResponse = createSelector(
  selectAuthState,
  (state: AuthState) => state.registerResponse
);

export const selectErrorMessage = createSelector(
  selectAuthState,
  (state: AuthState) => state.error
);

export const selectLoading = createSelector(
  selectAuthState,
  (state: AuthState) => state.loading
);
