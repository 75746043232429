import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import {
  CONFIRM_LEAVE_CHANGES_MSG,
  CONFIRM_LEAVE_PREDICT_MSG,
} from '@src/app/core/constants/system.constant'; // } from '@app/core/constants/system-list.constant';
import { ConfirmationDialogService } from '@src/app/core/services/confirmation-modal.service';
import { SharedDataService } from '@src/app/core/services/shared-data.service';
import { ComponentCanDeactivate } from '../interfaces';

@Injectable()
export class UnsavedDataChangesGuard {
  private readonly confirmMsg: string = CONFIRM_LEAVE_CHANGES_MSG;
  private dataModified: any;
  /**
   * Constructs a new instance of the UnsavedDataChangesGuard class.
   *
   * @param {Router} _router - The Angular Router instance.
   * @param {TranslateService} translate - The Angular TranslateService instance.
   * @param {ConfirmationDialogService} confirmationDialogService - The ConfirmationDialogService instance.
   * @param {SharedDataService} sharedDataService - The SharedDataService instance.
   */
  constructor(
    private readonly _router: Router,
    private readonly translate: TranslateService,
    private readonly confirmationDialogService: ConfirmationDialogService,
    private readonly sharedDataService: SharedDataService,
  ) {
    if (this._router.url.toLowerCase().includes('inference')) {
      this.confirmMsg = CONFIRM_LEAVE_PREDICT_MSG;
    }
    this.sharedDataService.dataModified$.subscribe(res => {
      this.dataModified = res;
    });
  }

  /**
   * Checks if the component can be deactivated. If the component has a canDeactivate method and it returns true,
   * the function returns true. Otherwise, it displays a confirmation dialog to the user asking if they are sure they
   * want to leave the screen without waiting for the pending changes to be saved. If the user confirms, the function
   * returns true. If the user cancels or the component does not have a canDeactivate method, the function returns false.
   *
   * @param {ComponentCanDeactivate} component - The component to check if it can be deactivated.
   * @returns {boolean | Promise<boolean>} - Returns true if the component can be deactivated, false otherwise.
   */
  canDeactivate(component: ComponentCanDeactivate) {
    if (!component) {
      return this.dataModified?.isModified ? this.openDialog() : true;
    }
    // if there are no pending changes, just allow deactivation; else confirm first
    return component &&
      component.canDeactivate &&
      component.canDeactivate() &&
      this.dataModified?.isModified
      ? this.openDialog()
      : true;
  }

  /**
   * Opens a confirmation dialog and returns a promise that resolves to a boolean value.
   * The dialog displays a warning icon, a message asking for confirmation to leave the page with unsaved changes,
   * and two buttons: "Leave Page" and "Cancel".
   * If the user clicks "Leave Page", the function sets `isModified` to `false` and returns `true`.
   * If the user clicks "Cancel" or closes the dialog, the function returns `false`.
   *
   * @return {Promise<boolean>} A promise that resolves to a boolean value indicating the user's choice.
   */
  async openDialog() {
    const result = await this.confirmationDialogService.confirm({
      extraIcon: 'icon-warning-2',
      message: this.translate.instant('MSGS.GENERAL.UNSAVED_CHANGES'),
      btnOkText: this.translate.instant('GENERAL.BUTTONS.LEAVE_PAGE'),
      btnCancelText: this.translate.instant('GENERAL.BUTTONS.CANCEL'),
    });
    if (result) {
      this.sharedDataService.dataModified.next({ isModified: false });
    }
    return result;
  }
}
