import { NgModule } from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import * as routesList from './core/constants/routes.constant';
import { ErrorPageComponent } from '@components/error-page/error-page.component';
import { AuthEffects } from '@features/auth/store/auth.effects';
import { authReducer } from '@features/auth/store/auth.reducer';
import { CategoriesEffects } from '@features/landing-page/store/categories.effect';
import { categoriesReducer } from '@features/landing-page/store/categories.reducer';
import { ProfessionalsEffects } from '@features/stores/professionals-store/professionals.effect';
import { professionalsReducer } from '@features/stores/professionals-store/professionals.reducer';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./features/views/views.component')
      .then((comp) => comp.ViewsComponent),
    children: [
      {
        path: '',
        loadChildren: () => import('./features/views/views.routes')
          .then((views) => views.routes),
      }
    ]
  },
  {
    path: routesList.AUTH_PATH,
    loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./features/views/create-profile/create-profile.module')
      .then((route) => route.CreateProfileModule),
    data: { isEdit: true },
  },

  {
    path: 'create-profile',
    loadChildren: () => import('./features/views/create-profile/create-profile.module')
      .then((route) => route.CreateProfileModule),
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    data: {
      type: 404,
    },
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent,
  },
  { path: '**', redirectTo: 'error', pathMatch: 'full' },
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled'
};

@NgModule({
  imports: [
    BrowserModule,
    RouterModule.forRoot([], routerOptions),
    StoreModule.forRoot({
      auth: authReducer,
    }),
    EffectsModule.forRoot([
      AuthEffects,
    ]),
    StoreModule.forFeature('categories', categoriesReducer),
    StoreModule.forFeature('professionals', professionalsReducer),
    EffectsModule.forFeature([
      CategoriesEffects,
      ProfessionalsEffects,
    ]),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
