import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ListDetails } from "../types/option.type";
import { Professional, ProfessionalDetail } from "../types/professionals.type";

@Injectable({
  providedIn: 'root',
})
export class ProfessionalListingRepository {
  public constructor(
    private readonly httpClient: HttpClient,
  ) {}

  public getProfessionals(params: HttpParams): Observable<ListDetails<Professional>> {
    return this.httpClient.get<ListDetails<Professional>>('/professional/profile/all-professionals', {
      params,
    });
  };

  public getProfessional(id: number): Observable<ProfessionalDetail> {
    return this.httpClient.get<ProfessionalDetail>(`/professional/profile/${id}`);
  };
}