{
  "PAGE_TITLE": "S.I.D",
  "APP_TITLE": "Sid",
  "ENGLISH": "Anglais",
  "FRENCH": "Français",
  "COMPONENTS": {
    "DASHBOARD": "Tableau de bord",
    "USERS": {
      "USER_PROFILE": "Profil de l'utilisateur",
      "PERFORMED_ON": "Exécuté sur",
      "ABOUT": "À propos",
      "ACTOR": "Acteur",
      "TIME": "Heure",
      "DATE": "Date",
      "MODULE": "Module",
      "SUB_MODULE": "Sous-Module",
      "UPLOAD_PHOTO": "Télécharger la photo",
      "UPDATE": "Mise à jour",
      "FIRST_NAME": "Prénom",
      "LAST_NAME": "Nom de famille",
      "DATE_OF_BIRTH": "Date de naissance",
      "GENDER": "Genre",
      "MALE": "Homme",
      "FEMALE": "Femme",
      "ROLE": "Rôle",
      "CREATION_DATE": "Date de création",
      "EMAIL_ADDRESS": "Adresse E-mail",
      "CONTACT_NUMBER": "Numéro de contact",
      "SELECT_ROLES": "Sélectionner les rôles",
      "EMAIL": "E-mail",
      "USER_ID": "Identifiant de l'employé",
      "LANDLINE_NUMBER": "Numéro de téléphone fixe",
      "MOBILE_NUMBER": "Numéro de téléphone portable",
      "SEARCH_BY_COUNTRY": "Recherche par nom ou code de pays",
      "PASSWORD": "Mot de passe",
      "NEW_PASSWORD": "Nouveau mot de passe",
      "PASSWORD_INFO": "Au moins 8 caractères, une lettre majuscule, un chiffre et un caractère spécial",
      "CHANGE_PASSWORD": "Modifier le mot de passe",
      "OFFICIAL_DETAILS": "Détails officiels",
      "OFFICIAL_CONTACT_NUMBER": "Numéro de contact officiel",
      "OFFICIAL_EMAIL_ADDRESS": "Adresse E-mail officielle",
      "DESIGNATION": "Statut d'Emploi",
      "USER_ADDRESS": "Adresse de l'utilisateur",
      "COUNTRY": "Pays de résidence",
      "SELECT_COUNTRY": "Sélectionner le pays",
      "CITY": "Ville",
      "SELECT_CITY": "Sélectionner la ville",
      "COMPLETE_ADDRESS": "Compléter l'adresse",
      "USER_DOCUMENTS": "Documents de l'utilisateur",
      "UPLOAD": "Télécharger",
      "DRIVING_LICENSE_NUMBER": "Numéro de permis de conduire",
      "DRIVING_LICENSE_EXPIRY_DATE": "Date d'expiration du permis de conduire",
      "NATIONAL_IDENTITY_NUMBER": "Numéro d'identité nationale",
      "UPLOADED_ON": "Téléchargé le",
      "DOWNLOAD": "Télécharger",
      "USER_ACTIVITIES": "Activités de l'utilisateur",
      "IMPORT_FILE_NAME": "modèle-importation-utilisateurs",
      "DOWNLOAD_TEMPLATE": "Dakar Dem Dik - Utilisateurs - {{date}} - {{time}}",
      "LENGTH_SIX_DIGITS": "Longueur : 6 chiffres",
      "LENGTH_EIGHT_SIXTEEN_CHARACTERS": "Longueur : 8 à 16 caractères",
      "ONE_CAPITAL": "Au moins 1 lettre majuscule",
      "ONE_LOWER": "Au moins 1 lettre minuscule",
      "ONE_NUMBER": "Au moins 1 chiffre",
      "PASSENGER": "Passager",
      "VALIDATOR": "Validateur"
    },
    "STOPS": {
      "TITLE": "Détails de l'arrêt",
      "ADD": "Ajouter un arrêt",
      "IMPORT_FILE_NAME": "modèle-importation-arrêt ",
      "DOWNLOAD_TEMPLATE": "Dakar Dem Dik - Arrêts - {{date}} - {{time}}",
      "STATION": "Station",
      "FIELDS": {
        "NAME": "Nom de l'arrêt",
        "ENTER_STOP_NAME": "Entrez le Nom de l'Arrêt",
        "ZONE_SUBZONE": "Zone / Sous-zone",
        "DESCRIPTION": "Description",
        "LATITUDE": "Latitude",
        "LONGITUDE": "Longitude",
        "GEO_LOCATION": "Géolocalisation",
        "SELECT_GEO_LOCATION": "Sélectionner la géolocalisation",
        "STOP_ID": "ID",
        "ENTER_STOP_ID": "Entrer l'ID de l'arrêt",
        "TYPE": "Type",
        "SELECT_TYPE": "Sélectionner le Type"
      }
    },
    "LOGIN": {
      "TITLE": "Connexion",
      "FIELDS": {
        "EMAIL": "Adresse e-mail",
        "PASSWORD": "Mot de passe",
        "FORGOT_PASSWORD": "Mot de passe oublié",
        "OTP": "CUU",
        "ENTER_OTP": "Saisir le CUU",
        "OTP_SENT_TO": "Le CUU est envoyé à",
        "CHECK_INBOX": "veuillez vérifier votre boîte de réception",
        "INCASE_YOU_DIDNT_RECEIVE": "Si vous ne l'avez pas reçu, cliquez sur",
        "CREDENTIALS_VERIFIED": "Vos informations d'identification ont été vérifiées. Veuillez saisir le CUU pour une authentification ultérieure.",
        "PLEASE_CHECK_INBOX": "Un CUU sera envoyé à votre adresse e-mail",
        "UNABLE_TO_LOGIN": "Impossible de se connecter",
        "RESEND_OTP": "Renvoyer le CUU",
        "USER_LOGGED_IN": "L'utilisateur s'est connecté avec succès",
        "UNABLE_TO_VERIFY": "Impossible de vérifier le CUU",
        "OTP_DISABLED": "Le bouton Renvoyer le CUU est désactivé pour",
        "OTP_SENT": "Un CUU a été envoyé à votre adresse e-mail",
        "RESET_LINK": "L'adresse e-mail saisie est valide. Le lien de réinitialisation du mot de passe a été envoyé avec succès",
        "UNABLE_TO_RESET": "Impossible de réinitialiser le mot de passe",
        "RESET_LINK_INVALID": "Le lien de réinitialisation du mot de passe n'est pas valide",
        "PASSWORD_RESET_SUCCESSFULLY": "Réinitialisation du mot de passe réussie",
        "PASSWORD_DONOT_MATCH": "Les mots de passe ne correspondent pas",
        "RESET_PASSWORD_LINK_SENT": "Le lien de réinitialisation du mot de passe sera envoyé à votre adresse e-mail",
        "RESEND_LINK": "Renvoyer le lien",
        "SET_NEW_PASSWORD": "Définir un nouveau mot de passe",
        "ENTER_NEW_PASSWORD": "Saisir le nouveau mot de passe",
        "CONFIRM_NEW_PASSWORD": "Confirmer le nouveau mot de passe",
        "PASSWORD_VALIDATION": "Au moins 8 caractères, une lettre majuscule, un chiffre et un caractère spécial."
      }
    },
    "ERROR_PAGE": {
      "TITLE": "Page non trouvée",
      "TITLE_500": "Erreur interne du serveur",
      "TITLE_ACCESS_DENIED": "Accès refusé",
      "TITLE_SOMETHING_WENT_WRONG": "Quelque chose s'est mal passé",
      "TITLE_INTERNAL_SERVER": "Erreur interne du serveur",
      "DESCRIPTION": "Le lien que vous avez suivi peut être brisé ou la page a peut-être été supprimée.",
      "DESCRIPTION_500": "Une erreur s'est produite. Veuillez réessayer ultérieurement.",
      "DESCRIPTION_ACCESS_DENIED": "Vous n'avez pas la permission de voir cette page.",
      "DESCRIPTION_SOMETHING_WENT_WRONG": "Il semble que quelque chose se soit mal passé. Nous travaillons dessus.",
      "BACK_TO_HOMEPAGE": "Retour à la page d'accueil"
    }
  },
  "COMMON": {
    "TEXT": {
      "URBAN": "Urbain",
      "INTERCITY": "Interurbain",
      "ACTIVE": "Actif",
      "INACTIVE": "Inactif",
      "DRAFT": "Brouillon",
      "PUBLISHED": "Publié",
      "PENDING": "En Attente",
      "IN_PROGRESS": "En Cours",
      "SENT": "Envoyé",
      "SCHEDULED": "Programmé",
      "SETTLED": "Réglé",
      "UNSETTLED": "Non Réglé",
      "DAMAGED": "Endommagé",
      "OBSOLETE": "Obsolète",
      "INREPAIR": "En Réparation",
      "DONE": "Terminé",
      "ANONYMOUS": "Anonyme",
      "BLACKLISTED": "Mis sur Liste Noire",
      "NEW": "Nouveau",
      "ASSIGNED": "Assigné",
      "NOT_ASSIGNED": "Non Assigné",
      "READY": "Prêt",
      "CANCELLED": "Annulé",
      "SEARCH": "Recherche",
      "DATE": "Date ",
      "DEPARTURE_TIME": "Heure de départ",
      "START_DATE": "Date de début",
      "END_DATE": "Date de fin",
      "START_TIME": "Heure de début",
      "END_TIME": "Heure de fin",
      "SELECT_START_TIME": "Sélectionner l'heure de début",
      "SELECT_END_TIME": "Sélectionner l'heure de fin",
      "ADD_CUSTOM_TIME": "Ajouter une heure personnalisée 00:00",
      "ENDS_ON": "Se termine le",
      "SELECT_DATE": "Sélectionner la date",
      "NEVER_ENDS": "Ne se termine jamais",
      "OUT_OF_SERVICE": "Hors Service",
      "OUTOFSERVICE": "Hors Service",
      "MAINTENANCE": "Maintenance",
      "DURATION": "Durée",
      "MONDAY": "Lundi",
      "TUESDAY": "Mardi",
      "WEDNESDAY": "Mercredi",
      "THURSDAY": "Jeudi",
      "FRIDAY": "Vendredi",
      "SATURDAY": "Samedi",
      "SUNDAY": "Dimanche",
      "SECONDS": "Secondes",
      "VALID_HOURS": "Veuillez saisir des heures (0-23) et des minutes (0-59) valides.",
      "TIME_FORMAT": "Veuillez ajouter l'heure dans ce format : 00:00",
      "OCCURRENCE": "Occurrence",
      "NO_TRIPS_ADDED": "Aucun Voyage Ajouté",
      "STATUS": "Statut",
      "LINE": "Ligne",
      "DATE_FROM": "Date de",
      "DATE_TO": "Date à",
      "BASED_ON": "Basé sur",
      "LOCATIONS": "Emplacements",
      "COUNTRY": "Pays",
      "ZONES_AVAILABILITY": "Disponibilité des zones",
      "MODULE": "Module",
      "DIRECTION": "Direction",
      "PASS_CATEGORY": "Catégorie de l'Abonnement",
      "CITY": "Ville",
      "ROLE": "Rôle",
      "VEHICLE": "Véhicule",
      "CONDUCTEUR_RECEVEUR": "Chauffeur / Receveur",
      "TYPE_PLACEHOLDER": "Type",
      "FROM_DATE": "De la date",
      "TO_DATE": "À la date",
      "TYPE": "Type",
      "SELECTED_PATTERNS_OF_SAME_TYPE": "Les modèles sélectionnés du même type ne peuvent pas se chevaucher",
      "REPEATED_WEEKDAY": "on weekdays ",
      "REPEATED_WEEKEND": "on weekends ",
      "ONE_TIME": "Une fois ",
      "DAILY": "Tous les jours ",
      "CUSTOM": "date personnalisée ",
      "ON_MONDAY": "le Lundi ",
      "ON_TUESDAY": "le Mardi ",
      "ON_WEDNESDAY": "le Mercredi ",
      "ON_THURSDAY": "le Jeudi ",
      "ON_FRIDAY": "le Vendredi ",
      "ON_SATURDAY": "le Samedi ",
      "ON_SUNDAY": "le Dimanche ",
      "AND": " et ",
      "ON": "sur"
    },
    "VALIDATION": {
      "NOT_FOUND": "Non trouvé",
      "REQUIRED": "Requis",
      "INVALID_FORMAT": "Format non valide",
      "INVALID_DATE_RANGE": "Plage de dates non valide",
      "INVALID_PASSWORD_FORMAT": "Format de Mot de Passe Incorrect",
      "USER_ID_VALIDATION": "L'identifiant de l'utilisateur doit comporter 6 à 8 caractères",
      "START_END_STOPS_REQUIRED": "Les arrêts de début et de fin sont obligatoires !",
      "INVALID_STOPS_DATA": "Données d'arrêt non valables ! Veuillez saisir les données complètes",
      "INVALID_BLOCKS_DATA": "Données de positions non valides!Veuillez saisir les données complètes",
      "INVALID_PATTERNS_DATA": "Données de modèles non valides! Veuillez saisir les données complètes",
      "PATTERNS_REQUIRED": "Les modèles sont obligatoires !",
      "INVALID_EMAIL_FORMAT": "Format d'e-mail non valide",
      "ENTER_REQUIRED_FIELDS": "Veuillez saisir les champs obligatoires !",
      "MAX_DISCOUNT_VALUE": "La valeur de la remise doit être comprise entre 0 et 100",
      "MAX_CAPACITY_VALUE": "La valeur de la capacité doit être comprise entre 0 et 100",
      "DRIVERS_ERROR": "Ce chauffeur est déjà assigné à une autre service",
      "DRIVERS_ERROR_THIS": "Ce chauffeur est déjà assigné à ce service",
      "CONDUCTORS_ERROR": "Ce receveur est déjà assigné à un autre service",
      "CONDUCTORS_ERROR_THIS": "Ce receveur est déjà assigné à ce service",
      "COST_VALIDATION": "Maximum cost must be greater than Minimum Cost",
      "60_VALIDATION": "must be less than 60 characters",
      "MAX_VALUE": "The value cannot exceed the maximum limit of {{max}}",
      "MIN_VALUE": "The value cannot be less than the minimum limit of {{ min }}"
    }
  },
  "GENERAL": {
    "BUTTONS": {
      "SELECT": "Sélectionner",
      "NEW": "Nouveau",
      "REFRESH": "Actualiser",
      "ANONYMOUS": "Anonyme",
      "BLACKLISTED": "Liste noire",
      "ASSIGNED": "Assigné",
      "VIEW_ON_MAP": "Voir sur la carte",
      "CANCEL": "Annuler",
      "APPLY": "Appliquer",
      "INSERT": "Insérer",
      "CONFIRM": "Confirmer",
      "LEAVE_PAGE": "Quitter la Page",
      "FILTERS": "Filtres",
      "ACTIONS": "Actions",
      "EXPIRED": "Expiré",
      "UPCOMING": "A venir",
      "TODAY": "Aujourd'hui",
      "TOMORROW": "Demain",
      "YESTERDAY": "Hier",
      "LAST_7_DAYS": "Les 7 derniers jours",
      "CURRENT": "En cours",
      "PAST": "Passé",
      "NEXT": "Suivant",
      "CLOSE": "Fermer",
      "SUBMIT": "Soumettre",
      "DELETE": "Supprimer",
      "SAVE": "Sauvegarder",
      "UPDATE": "Mettre à jour",
      "RESET": "Réinitialiser",
      "ADD": "Ajouter",
      "EDIT": "Modifier",
      "CREATE": "Créer",
      "ACTIVATE": "Activer",
      "DEACTIVATE": "Désactiver",
      "PENDING": "En attente",
      "DAMAGED": "Endommagé",
      "OBSOLETE": "Obsolète",
      "OUT_OF_SERVICE": "Hors service",
      "INREPAIR": "En Réparation",
      "MAINTENANCE": "Maintenance",
      "DUPLICATE": "Dupliquer",
      "DRAFT": "Définir comme brouillon",
      "SAVE_DRAFT": "Enregistrer comme brouillon",
      "SAVE_PENDING": "Définir comme En Attente",
      "SAVE_CHANGES": "Enregistrer les modifications",
      "PUBLISH": "Publier",
      "READY": "Prêt",
      "MARK_AS_READY": "Marquer comme Prêt",
      "UNASSIGN": "Annuler l'attribution",
      "SELECT_ALL": "Sélectionner tout",
      "UNSELECT_ALL": "Désélectionner tout",
      "SAVE_DRAFT_GRAPH": "Enregistrer comme brouillon et voir le graphique",
      "DOWNLOAD_TEMPLATE": "Télécharger le modèle",
      "DOWNLOAD_SELECTED_ROWS": "Télécharger les lignes sélectionnées",
      "DOWNLOAD_FILTERED_ROWS": "Télécharger les lignes filtrées",
      "IMPORT": "Importer",
      "EXPORT": "Exporter",
      "SET_AS_DRAFT": "Définir comme brouillon",
      "SET_AS_PENDING": "Définir comme en Attente",
      "SAVE_CONFIGURE": "Enregistrer et Configurer les Services",
      "VIEW_ALL_ZONES": "Afficher toutes les Zones"
    }
  },
  "MSGS": {
    "GENERAL": {
      "INVALID_PHONE_NUMBER": "Numéro de téléphone invalide",
      "REQUIRED_EMAIL": "L'adresse e-mail est requise",
      "SOMETHING_WRONG": "Un problème s'est produit",
      "SELECT_ROW": "Veuillez sélectionner au moins une ligne.",
      "SELECT_FILTER": "Veuillez sélectionner au moins un filtre.",
      "CONFIRM_DEPARTURE_TIMES": "Veuillez confirmer les heures de départ pour continuer",
      "CONFIRM_PUBLISH_SERVICES": "La publication d'une création de service est irréversible ; la création de service ne pourra pas être modifiée par la suite ! Les nouveaux horaires créés ultérieurement dans cette plage de dates ne seront plus inclus.",
      "DRAFT_TIMETABLES-DETECTED": "Nous détectons des Brouillon d'horaires dans la même plage de dates. En les publiant, ces horaires ainsi que les nouveaux ne seront plus inclus.",
      "INVALID_FILE": "Type de fichier non valide ! Seuls les formats jpg, jpeg, png et pdf sont autorisés.",
      "INVALID_IMAGE": "Type d'image non valide ! Seuls les formats jpg, jpeg, png sont autorisés.",
      "DONE": "Terminé",
      "ALL_ZONES": "Toutes les zones",
      "ARE_YOU_SURE": "Êtes-vous sûr de vouloir effectuer cette action ?",
      "NO_RESULTS_FOUND": "Aucun résultat trouvé",
      "NO_TRANSACTION_EXISTS": "Aucune transaction n'a été trouvée !",
      "NO_RESULTS_FOUND_GENERIC": "Enregistrement inexistant! Cliquez sur Nouveau ou affinez votre recherche.",
      "FILE_TOO_LARGE": "Le fichier sélectionné est trop volumineux. Veuillez sélectionner un fichier de moins de 200 Ko",
      "IMAGE_TOO_LARGE": "L'image sélectionnée est trop grande. Veuillez sélectionner une image de moins de 2 Mo",
      "LOADING": "Chargement en cours, veuillez patienter",
      "START_DATE_ERROR": "La date de début doit être antérieure à la date de fin",
      "START_TIME_ERROR": "L'heure de début doit être antérieure à l'heure de fin",
      "END_TIME_ERROR": "L'heure de fin doit être postérieure à l'heure de début",
      "INVALID_END_TIME": "Heure de fin invalide",
      "START_END_TIME_SAME": "L'heure de début et l'heure de fin ne peuvent pas être identiques",
      "YES": "OUI",
      "NO": "NON",
      "SEGMENT": "Section",
      "STOP": "Arrêt",
      "UNSAVED_CHANGES": "Êtes-vous sûr de vouloir quitter l'écran sans enregistrer les modifications ?",
      "DATA_LOST": "This action may result in the loss of unsaved data. Are you sure you want to proceed?",
      "CONFIRMATION_PROFILE": "Are you sure you want to submit your Profile?"
    },
    "USERS": {
      "EDIT_SUPER_ADMIN": "Le super admin ne peut pas être modifié",
      "DELETE_TEXT": "Êtes-vous sûr de vouloir supprimer cet utilisateur ?",
      "CREATE_TEXT": "Utilisateur créé avec succès",
      "SAVE_AS_DRAFT_TEXT": "Utilisateur enregistré avec succès en tant qu'en attente",
      "UPDATE_TEXT": "Données de l'utilisateur mises à jour avec succès",
      "UNABLE_TO_UPDATE": "Impossible de mettre à jour l'utilisateur",
      "DELETE_TEXT_SUCCESS": "Utilisateur supprimé avec succès",
      "UNABLE_TO_DELETE": "Impossible de supprimer l'utilisateur",
      "UPDATE_TEXT_STATUS": "Le(s) utilisateur(s) sélectionné(s) a/ont été mis à jour avec succès",
      "UNABLE_TO_UPDATE_TEXT_STATUS": "Impossible de mettre à jour le statut de l'utilisateur",
      "UPDATE_PASSWORD": "Mise à jour réussie du mot de passe de l'utilisateur",
      "UNABLE_TO_UPDATE_PASSWORD": "Impossible de mettre à jour le mot de passe de l'utilisateur",
      "DELETE_USER": "Êtes-vous sûr de vouloir supprimer cet utilisateur ?",
      "CREATE_USER": "Utilisateur créé avec succès",
      "SAVE_AS_DRAFT_USER": "Utilisateur sauvegardé comme brouillon avec succès",
      "UPDATE_USER_PROFILE": "Le profil de l'utilisateur a été mis à jour avec succès",
      "UPDATE_PROFILE": "Profil mis à jour avec succès ! Veuillez actualiser la page ou vous déconnecter et vous reconnecter pour voir les modifications",
      "UNABLE_TO_UPDATE_PROFILE": "Impossible de mettre à jour le profil de l'utilisateur",
      "UPDATE_USER_ADDRESS": "L'adresse de l'utilisateur a été mise à jour avec succès",
      "UNABLE_TO_UPDATE_ADDRESS": "Impossible de mettre à jour l'adresse de l'utilisateur",
      "UPDATE_USER_DETAILS": "Données de l'utilisateur mises à jour avec succès",
      "UNABLE_TO_UPDATE_DETAILS": "Impossible de mettre à jour les coordonnées de l'utilisateur",
      "UPDATE_USER_DOCUMENTS": "Document de l'utilisateur mis à jour avec succès",
      "UPDATE_UPLOAD_USER_DOCUMENTS": "Les documents de l'utilisateur ont été mis à jour et téléchargés avec succès",
      "UNABLE_TO_UPDATE_DOCUMENTS": "Impossible de mettre à jour les documents de l'utilisateur",
      "UNABLE_TO_CREATE": "Impossible de créer un utilisateur",
      "DELETE_DOCUMENT_SUCCESS": "Document supprimé avec succès",
      "UNABLE_TO_DELETE_DOCUMENT": "Impossible de supprimer le document",
      "DOWNLOAD_DOCUMENT_SUCCESS": "Document téléchargé avec succès",
      "UNABLE_TO_DOWNLOAD_DOCUMENT": "Impossible de télécharger le document",
      "DELETE_USER_SUCCESS": "Utilisateur supprimé avec succès",
      "UPDATE_USER_STATUS": "Le(s) utilisateur(s) sélectionné(s) a/ont été mis à jour avec succès",
      "UNABLE_TO_UPDATE_USER_STATUS": "Impossible de mettre à jour le statut de l'utilisateur",
      "UNABLE_TO_DOWNLOAD_TEMPLATE": "Impossible de télécharger le modèle",
      "UNABLE_TO_EXPORT": "Impossible d'exporter des utilisateurs",
      "UNABLE_TO_EXPORT_NFC": "Impossible d'exporter les cartes NFC",
      "ONLY_EXCEL_FILE_ERROR": "Type de fichier non valide ! Seuls les fichiers .xls et .xlsx sont autorisés.",
      "ONLY_CSV_FILE_ERROR": "Type de fichier invalide ! Seuls les fichiers .csv sont autorisés.",
      "SELECT_FILE": "Veuillez choisir un fichier.",
      "IMPORT_USERS": "Le processus d'importation a commencé. Vous serez informé par e-mail dès qu'il sera terminé.",
      "UNABLE_TO_IMPORT": "Le processus d'importation n'a pas pu être lancé. Réviser le fichier soumis",
      "UNABLE_TO_DELETE_SUPER_ADMIN": "Un ou plusieurs utilisateurs sélectionnés ont le rôle de 'Super Admin'. Les utilisateurs avec le rôle 'Super Admin' ne peuvent pas être supprimés",
      "OWN_PROFILE_DELETION": "Vous ne pouvez pas désactiver ou supprimer votre profil utilisateur",
      "USER_HAS_NO_ACTIVITIES": "L'utilisateur n'a aucune activité",
      "SUPER_ADMIN_STATUS_CHANGE": "L'utilisateur de ce rôle ne peut pas modifier le statut de Super-Administrateur"
    },
    "PORTFOLIO": {
      "PROFILE_CREATED": "Profile reated successfully"
    }
  },
  "BREADCRUMBS": {
    "USER_MANAGEMENT": {
      "TITLE": "Gestion des utilisateurs",
      "USER_LISTING": "Utilisateurs",
      "ADMIN_USER": "Utilisateur Administrateur",
      "APP_USER": "Utilisateur de l'Application",
      "ADD_USER": "Ajouter un utilisateur",
      "EDIT_USER": "Modifier un utilisateur"
    },
    "DASHBOARD": {
      "TITLE": "Tableau de bord"
    },
    "PROFILE": {
      "TITLE": "Profil"
    },
    "SYSTEM_LOGS": {
      "TITLE": "Journal du système"
    }
  },
  "PAGINATION": {
    "SHOWING": "Affichage de",
    "TO": "à",
    "OF": "sur",
    "ENTERIES": "entrées"
  },
  "MENU": {
    "Main": "Principal",
    "DASHBOARD": "Tableau de bord",
    "SYSTEM_LOGS": "Journal du système",
    "MODULE": "MODULE",
    "SETTINGS": "Paramètres",
    "CUSTOMER": "Client",
    "FINANCES": "Finances",
    "LINE_COMPARISON": "Comparaison des Lignes",
    "RIDERSHIP": "Fréquentation",
    "VEHICLE": "Véhicule",
    "BUS_CONDUCTOR": "Receveur",
    "SALES_AGENT": "Agent de Point de Ventes",
    "POS_OPERATOR": "Opérateur POS",
    "SHIPMENT": "Expédition",
    "REPORTS": "Rapports",
    "ROLES_AND_PERMISSIONS": "Rôles et autorisations",
    "COLLECTION_REVENUE": "Revenu de la collecte",
    "USER_MANAGEMENT": "Gestion des utilisateurs",
    "ADMIN_USERS": "Utilisateurs administrateurs",
    "APP_USERS": "Utilisateurs de l'application",
    "FARES": "Tarifs",
    "RUNTIME_PATTERNS": "Modèles d'exécution",
    "TIMETABLE": "Horaires",
    "E_TICKETING": "Collection Automatique Des Tarifs",
    "E_TICKETING_MINI": "CAT",
    "URBAN_FARE": "Tarifs urbains",
    "LINES": "Lignes",
    "SEGMENTS": "Sections",
    "NETWORK_AND_PLANNING": "Réseau",
    "STOPS": "Arrêts",
    "TRIPS": "Voyages ",
    "ZONES": "Zones",
    "SCHEDULE": "Planification",
    "SCHEDULE_SERVICES": "Création de Services",
    "SCHEDULE_EMPLOYEE_ROSTER": "Planification des Employés",
    "SCHEDULE_EMPLOYEE_ENGAGEMENT": "Engagement des employés",
    "INTERCITY_FARE": "Tarifs interurbains",
    "PASS_FARE": "Abonnements et Tarifs Spéciaux",
    "FRAUDS_AND_FINES": "Infractions et Amendes",
    "HOLIDAYS": "Jours fériés",
    "PLANS": "Planification des Services",
    "ADD_SERVICE_PLANS": "Planification des Services",
    "VEHICLE_DISPATCHING": "Répartition des Véhicules",
    "NFC_CARDS": "Cartes NFC",
    "DEVICES": "Dispositifs",
    "FLEET_MANAGEMENT": "Gestion de flotte",
    "VEHICLES": "Véhicules",
    "LIVE_TRACKING": "Suivi en direct",
    "NOTIFICATIONS": "Notifications",
    "PASSENGER_NOTIFICATIONS": "Notifications aux Passagers",
    "APP_NOTIFICATIONS": "Notifications de l'Application",
    "SYSTEM_SETTINGS": "Paramètres du Système",
    "URBAN_NETWORK_AND_PLANNING": "Réseau Urbain et Planification",
    "INTERCITY_NETWORK_AND_PLANNING": "Réseau Interurbain et Planification",
    "FLEET_MANAGEMENT_SETTINGS": "Gestion de la Flotte",
    "GENERAL_SETTINGS": "Général",
    "TRANSACTION_SETTLEMENT": "Règlement des Transactions",
    "DYNAMIC_TABLES": "Tables Dynamiques"
  },
  "TABLE": {
    "BASED_ON": "Basé sur",
    "PERMISSIONS": "Autorisations"
  },
  "CONSTANTS": {
    "DEACTIVATED": "Désactivé",
    "DELETED": "Supprimé",
    "ADMIN_USER": "Admin User",
    "APP_USER": "App User",
    "STOP": "Arrêt",
    "STATION": "Station",
    "SEGMENT": "Section",
    "URBAN": "Urbain",
    "INTERCITY": "Interurbain",
    "SINGLE": "Individuel",
    "BULK": "Groupé",
    "IN": "Entrée",
    "OUT": "Sortie",
    "ONBOARD": "À bord",
    "HANDHELD": "Portable",
    "COMMERCIAL": "Commercial",
    "POINT_OF_SALES": "Point de Vente",
    "FX925": "Valideur FX925",
    "FX205": "Mobile/Android FX205",
    "FX408": "Tablette/Android FX408",
    "INNER_HANOVER_DISPLAY": "Affichage intérieur Hanover",
    "OUTER_HANOVER_DISPLAY": "Affichage extérieur Hanover",
    "LAPTOP": "Ordinateur Portable",
    "CAMERA": "Caméra",
    "THERMAL_PRINTER": "Imprimante thermique",
    "CARD_PRINTER": "Imprimante de cartes",
    "FREE": "Abonnement Gratuit",
    "SPECIAL": "Abonnement Spécial",
    "TIME_BASED": "Abonnement Basé sur le Temps",
    "NORMAL_PRIORITY": "Priorité Normale",
    "SYSTEM": "Système",
    "HIGH_PRIORITY": "Haute Priorité",
    "ONE_TIME": "Une fois",
    "REPEATED_WEEKDAY": "Répéter les jours de la semaine",
    "REPEATED_WEEKEND": "Répéter les week-ends",
    "DAILY": "Tous les jours",
    "CUSTOM": "Date personnalisée",
    "STATUS": {
      "ACTIVE": "Actif",
      "INACTIVE": "Inactif",
      "DRAFT": "Brouillon",
      "PENDING": "En Attente",
      "PUBLISHED": "Publié",
      "IN_PROGRESS": "En cours",
      "READY": "Prêt",
      "CANCELLED": "Annulé",
      "DONE": "Terminé",
      "OUTOFSERVICE": "Hors service",
      "MAINTENANCE": "Maintenance",
      "SETTLED": "Réglé",
      "UNSETTLED": "Non réglé",
      "SENT": "Envoyé"
    },
    "ROLE": {
      "DRIVER": "Chauffeur",
      "ATTENDANT": "Receveur"
    }
  }
}
