import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { AuthService } from '../services';

@Injectable({ providedIn: 'root' })
export class HasPermissionGuard {
  /**
   * Initializes a new instance of the class.
   *
   * @param {Router} router - The router service.
   * @param {AuthService} authService - The authentication service.
   */
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
  ) {}
  /**
   * Checks if the user has the permission to activate the route.
   *
   * @param {ActivatedRouteSnapshot} next - The next route snapshot.
   * @param {RouterStateSnapshot} state - The current router state snapshot.
   * @return {boolean} Returns true if the user has the permission, false otherwise.
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const routeRole = next.data['permission']; // get roles from route data
    const hasPermission = this.authService.checkPermission(routeRole); // get user roles from localStorage

    if (!hasPermission) {
      // navigate to 'no-access' page or any other page if the user doesn't have permission
      this.router.navigate(['error/no-access']);
      return false;
    }

    return true;
  }
}
