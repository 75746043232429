import { Injectable, OnDestroy } from '@angular/core';
import {
  ActivatedRoute,
  CanLoad,
  NavigationExtras,
  Route,
  Router,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthService } from '../services';


@Injectable()
export class CanLoadGuard implements CanLoad, OnDestroy {
  private _redirectTo: string | null = null;
  private readonly _onDestroy$: Subject<void> = new Subject<void>();

  /**
   * Initializes a new instance of the CanLoadGuard class.
   *
   * @param {Router} router - The router service.
   * @param {ActivatedRoute} activatedRoute - The activated route.
   * @param {AuthService} authService - The authentication service.
   */
  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService,
  ) {
    this.activatedRoute.queryParams.pipe(takeUntil(this._onDestroy$)).subscribe(params => {
      if (params['redirectTo']) {
        this._redirectTo = params['redirectTo'];
      }
    });
  }

  /**
   * The following checks if the access can be provided or not to the current user
   */
  canLoad(
    route: Route,
    segments: UrlSegment[],
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const redirectLink: string =
      this._redirectTo ||
      segments.reduce((previousValue, currentValue) => previousValue + '/' + currentValue.path, '');
    const extras: NavigationExtras = {
      queryParams: { redirectTo: redirectLink },
      queryParamsHandling: 'merge',
    };
    if (this.authService.isServer) {
      return this.goToRouteWithExtras('/', extras);
    } else if (this.authService.isLoggedIn()) {
      return true;
    }
    this.authService.setRedirectUrl();
    return this.goToRouteWithExtras('/auth/login', extras);
  }

  /**
   * Clean up resources and complete the subject when the component is destroyed.
   *
   * @return {void} Nothing is returned from this function.
   */
  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  /**
   * Navigates to a specified route with additional options and returns a Promise that resolves to a boolean.
   *
   * @param {string} url - The URL to navigate to.
   * @param {NavigationExtras} extras - The additional options for navigation.
   * @return {Promise<boolean>} A Promise that resolves to a boolean indicating the success of the navigation.
   */
  private goToRouteWithExtras(url: string, extras: NavigationExtras): Promise<boolean> {
    return this.router.navigate([url], extras).then().catch();
  }
}
