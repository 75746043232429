import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./views/base/base.component').then(comp => comp.BaseComponent),
    children: [
      {
        path: '',
        redirectTo: 'sign-in',
        pathMatch: 'full',
      },
      {
        path: 'sign-in',
        /**
         * Dynamically imports the LoginComponent and returns it as a Promise.
         *
         * @return {Promise<typeof LoginComponent>} A Promise that resolves to the LoginComponent class.
         */
        loadComponent: () =>
          import('./views/sign-in/sign-in.component').then(comp => comp.SignInComponent),
      },
      {
        path: 'register',
        /**
         * Dynamically imports the RegisterComponent and returns it as a Promise.
         *
         * @return {Promise<typeof RegisterComponent>} A Promise that resolves to the RegisterComponent class.
         */
        loadComponent: () =>
          import('./views/register/register.component').then(comp => comp.RegisterComponent),
      },
      {
        path: 'role-selection',
        loadComponent: () =>
          import('./views/role-selection/role-selection.component').then(
            comp => comp.RoleSelectionComponent,
          ),
      },
      {
        path: 'verification',
        loadComponent: () =>
          import('./views/verification/verification.component').then(
            comp => comp.VerificationComponent,
          ),
      },
      {
        path: 'expired',
        loadComponent: () =>
          import('./views/expired-verification/expired-verification.component').then(
            comp => comp.ExpiredVerificationComponent,
          ),
      },
      {
        //auth/set-password/4c01ddcd-a599-4df9-bdd4-bd8a3666813c
        path: 'set-password/:token',
        loadComponent: () =>
          import('./views/reset-password/reset-password.component').then(
            comp => comp.ResetPasswordComponent
          ),
      },
      {
        path: 'verify/:token',
        loadComponent: () =>
          import('./views/verification/verification.component').then(
            comp => comp.VerificationComponent,
          ),
      },
      {
        path: 'reset-password',
        /**
         * Dynamically imports the ResetPasswordComponent and returns it as a Promise.
         *
         * @return {Promise<typeof ResetPasswordComponent>} A Promise that resolves to the ResetPasswordComponent class.
         */
        loadComponent: () =>
          import('./views/reset-password/reset-password.component').then(
            comp => comp.ResetPasswordComponent,
          ),
      },
      {
        path: 'forgot-password',
        /**
         * Dynamically imports the ForgotPasswordComponent and returns it as a Promise.
         *
         * @return {Promise<typeof ForgotPasswordComponent>} A Promise that resolves to the ForgotPasswordComponent class.
         */
        loadComponent: () =>
          import('./views/forgot-password/forgot-password.component').then(
            comp => comp.ForgotPasswordComponent,
          ),
      },

    ],
  },
];

export const AuthRoutingModule = RouterModule.forChild(routes);
