import { createReducer, on } from '@ngrx/store';
import { clearError, login, loginFailure, loginSuccess, register, registerFailure, registerSuccess, resendVerification, resendVerificationFailure, resendVerificationSuccess, verifyToken, verifyTokenFailure, verifyTokenSuccess } from './auth.actions';
import { initialAuthState } from './auth.state';

export const authReducer = createReducer(
  initialAuthState,
  on(login, (state) => ({ ...state, loading: true })),
  on(loginSuccess, (state, { user, token }) => ({
    ...state,
    loading: false,
    user: user,
    token: token,
    error: null
  })),
  on(loginFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error
  })),

  on(register, (state) => ({ ...state, loading: true })),
  on(registerSuccess, (state, { response }) => ({
    ...state,
    loading: false,
    registerResponse: response,
    error: null
  })),
  on(registerFailure, (state, { error }) => ({
    ...state,
    loading: false,
    registerResponse: null,
    error: error
  })),

  on(verifyToken, (state) => ({ ...state, loading: true })),
  on(verifyTokenSuccess, (state, { message }) => ({
    ...state,
    loading: false,
    tokenVerified: true,
    error: null
  })),
  on(verifyTokenFailure, (state, { error }) => ({
    ...state,
    loading: false,
    tokenVerified: false,
    error: error
  })),

  on(resendVerification, (state) => ({ ...state, loading: true })),
  on(resendVerificationSuccess, (state, { message }) => ({
    ...state,
    loading: false,
    message: message,
    error: null
  })),
  on(resendVerificationFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error
  })),
  on(clearError, (state) => ({
    ...state,
    error: null,
  })),
);
