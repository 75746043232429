import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LoginPayload, LoginResponse, User } from "./types/auth.type";
import { RegisterPayload } from "./core/classes/register_payload";
import { RegisterResponse } from "./core/interfaces/register_response";

@Injectable({
  providedIn: 'root',
})
export class AuthRepository {
  public constructor(
    private readonly httpClient: HttpClient,
  ) {}

  public login(payload: LoginPayload): Observable<LoginResponse> {
    return this.httpClient.post<LoginResponse>('/auth/web-login', payload);
  }

  public register(payload: RegisterPayload): Observable<RegisterResponse> {
    return this.httpClient.post<RegisterResponse>('/auth/register', payload);
  }

  public getUserDetails(): Observable<User> {
    return this.httpClient.get<User>('/users/me');
  }

  public resetToken(): Observable<void> {
    return this.httpClient.post<void>('/auth/refresh-token', {
      refreshToken: localStorage.getItem('authToken'),
    });
  }

  public verifyToken(token: string): Observable<any> {
    return this.httpClient.post(`/auth/verify/${token}`, {});
  }

  public resendVerification(email: string): Observable<any> {
    return this.httpClient.post('/auth/resend-verification', {email});
  }
}
