import { createReducer, on } from '@ngrx/store';
import * as CategoriesActions from './categories.actions';
import { initialState } from './categories.state';

export const categoriesReducer = createReducer(
  initialState,
  on(CategoriesActions.loadCategories, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(CategoriesActions.loadCategoriesSuccess, (state, { categories }) => ({
    ...state,
    categories,
    loading: false,
  })),
  on(CategoriesActions.loadCategoriesFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),
  on(CategoriesActions.getSubcategories, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(CategoriesActions.getSubcategoriesSuccess, (state, { subcategories }) => ({
    ...state,
    loading: false,
    subcategories,
    error: null
  })),
  on(CategoriesActions.getSubcategoriesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error
  })),
  on(CategoriesActions.getProducts, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(CategoriesActions.getProductsSuccess, (state, { products }) => ({
    ...state,
    loading: false,
    products,
    error: null
  })),
  on(CategoriesActions.getProductsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error
  })),
  on(CategoriesActions.getBrands, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(CategoriesActions.getBrandsSuccess, (state, { brands }) => ({
    ...state,
    loading: false,
    brands,
    error: null
  })),
  on(CategoriesActions.getBrandsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error
  })),
  on(CategoriesActions.getTags, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(CategoriesActions.getTagsSuccess, (state, { tags }) => ({
    ...state,
    loading: false,
    tags,
    error: null
  })),
  on(CategoriesActions.getTagsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error
  })),
  on(CategoriesActions.getStyles, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(CategoriesActions.getStylesSuccess, (state, { styles }) => ({
    ...state,
    loading: false,
    styles,
    error: null
  })),
  on(CategoriesActions.getStylesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error
  })),
  on(CategoriesActions.getBaseMaterials, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(CategoriesActions.getBaseMaterialsSuccess, (state, { baseMaterials }) => ({
    ...state,
    loading: false,
    baseMaterials,
    error: null
  })),
  on(CategoriesActions.getBaseMaterialsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error
  })),
  on(CategoriesActions.getFinishes, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(CategoriesActions.getFinishesSuccess, (state, { finishes }) => ({
    ...state,
    loading: false,
    finishes,
    error: null
  })),
  on(CategoriesActions.getFinishesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error
  })),
);
