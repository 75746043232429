import { ListDetails } from "../../types/option.type";
import { Brand, Category, Product, Tag } from "../categories.type";

export interface CategoriesState {
  categories: Category[];
  subcategories: Category[];
  products: ListDetails<Product> | null;
  brands: Brand[];
  tags: Tag[];
  styles: Tag[];
  baseMaterials: Tag[];
  finishes: Tag[];
  loading: boolean;
  error: any;
}

export const initialState: CategoriesState = {
  categories: [],
  subcategories: [],
  products: null,
  brands: [],
  tags: [],
  styles: [],
  baseMaterials: [],
  finishes: [],
  loading: false,
  error: null,
};