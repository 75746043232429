{
  "PAGE_TITLE": "S.I.D",
  "APP_TITLE": "Sid",
  "ENGLISH": "English",
  "FRENCH": "French",
  "COMPONENTS": {
    "DASHBOARD": "Dashboard",
    "USERS": {
      "USER_PROFILE": "User Profile",
      "PERFORMED_ON": "Performed On",
      "ABOUT": "About",
      "ACTOR": "Actor",
      "TIME": "Time",
      "DATE": "Date",
      "MODULE": "Module",
      "SUB_MODULE": "Sub Module",
      "UPLOAD_PHOTO": "Upload Photo",
      "UPDATE": "Update",
      "FIRST_NAME": "First Name",
      "LAST_NAME": "Last Name",
      "DATE_OF_BIRTH": "Date Of Birth",
      "GENDER": "Gender",
      "MALE": "Male",
      "FEMALE": "Female",
      "ROLE": "Role",
      "CREATION_DATE": "Creation date",
      "EMAIL_ADDRESS": "Email Address",
      "CONTACT_NUMBER": "Contact Number",
      "SELECT_ROLES": "Select Roles",
      "EMAIL": "Email",
      "USER_ID": "Employee ID",
      "LANDLINE_NUMBER": "Landline Number",
      "MOBILE_NUMBER": "Mobile Number",
      "SEARCH_BY_COUNTRY": "Search by Country Name or Code",
      "PASSWORD": "Password",
      "NEW_PASSWORD": "New Password",
      "PASSWORD_INFO": "At least 8 characters, one capital letter, one number, and one special character",
      "CHANGE_PASSWORD": "Change Password",
      "OFFICIAL_DETAILS": "Official Details",
      "OFFICIAL_CONTACT_NUMBER": "Official Contact Number",
      "OFFICIAL_EMAIL_ADDRESS": "Official Email Address",
      "DESIGNATION": "Designation",
      "USER_ADDRESS": "User’s Address",
      "COUNTRY": "Country",
      "SELECT_COUNTRY": "Select Country",
      "CITY": "City",
      "SELECT_CITY": "Select City",
      "COMPLETE_ADDRESS": "Complete Address",
      "USER_DOCUMENTS": "User’s Documents",
      "UPLOAD": "Upload",
      "DRIVING_LICENSE_NUMBER": "Driving License Number",
      "DRIVING_LICENSE_EXPIRY_DATE": "Driving License Expiry Date",
      "NATIONAL_IDENTITY_NUMBER": "National Identity Number",
      "UPLOADED_ON": "Uploaded on",
      "DOWNLOAD": "Download",
      "USER_ACTIVITIES": "User Activities",
      "IMPORT_FILE_NAME": "import-users-template",
      "DOWNLOAD_TEMPLATE": "Dakar Dem Dik - Users - {{date}} - {{time}}",
      "LENGTH_SIX_DIGITS": "Length: 6 Digits",
      "LENGTH_EIGHT_SIXTEEN_CHARACTERS": "Length: 8 ~ 16 characters",
      "ONE_CAPITAL": "At least 1 uppercase letter",
      "ONE_LOWER": "At least 1 lowercase letter",
      "ONE_NUMBER": "At least 1 number",
      "PASSENGER": "Passenger",
      "VALIDATOR": "Validator"
    },
    "STOPS": {
      "TITLE": "Stop Details",
      "ADD": "Add Stop",
      "IMPORT_FILE_NAME": "import-stop-template",
      "DOWNLOAD_TEMPLATE": "Dakar Dem Dik - Stops - {{date}} - {{time}}",
      "STATION": "Station",
      "FIELDS": {
        "NAME": "Stop Name",
        "ENTER_STOP_NAME": "Enter Stop Name",
        "ZONE_SUBZONE": "Zone / Sub-Zone",
        "DESCRIPTION": "Description",
        "LATITUDE": "Latitude",
        "LONGITUDE": "Longitude",
        "GEO_LOCATION": "Geo Location",
        "SELECT_GEO_LOCATION": "Select Geo Location",
        "STOP_ID": "ID",
        "ENTER_STOP_ID": "Enter Stop Id",
        "TYPE": "Type",
        "SELECT_TYPE": "Select Type"
      }
    },
    "LOGIN": {
      "TITLE": "Login",
      "FIELDS": {
        "EMAIL": "Email Address",
        "PASSWORD": "Password",
        "FORGOT_PASSWORD": "Forgot Password",
        "OTP": "OTP",
        "ENTER_OTP": "Enter OTP",
        "OTP_SENT_TO": "OTP is sent to ",
        "CHECK_INBOX": "please check your inbox",
        "INCASE_YOU_DIDNT_RECEIVE": "incase you didn’t receive it please click",
        "CREDENTIALS_VERIFIED": "Your credentials have been verified. Please proceed to enter the OTP for further authentication.",
        "PLEASE_CHECK_INBOX": "An OTP will be sent to your email address.",
        "UNABLE_TO_LOGIN": "Unable to login",
        "RESEND_OTP": "Resend OTP",
        "USER_LOGGED_IN": "User Logged In Successfully",
        "UNABLE_TO_VERIFY": "Unable to verify OTP",
        "OTP_DISABLED": "Resend OTP button disabled for",
        "OTP_SENT": "An OTP has been sent to your email.",
        "RESET_LINK": "Entered email address is valid. Reset password link sent successfully",
        "UNABLE_TO_RESET": "Unable to reset password",
        "RESET_LINK_INVALID": "Reset password link is invalid",
        "PASSWORD_RESET_SUCCESSFULLY": "Password reset successfully",
        "PASSWORD_DONOT_MATCH": "Passwords do not match",
        "RESET_PASSWORD_LINK_SENT": "Reset password link will be sent to your email address",
        "RESEND_LINK": "Resend Link",
        "SET_NEW_PASSWORD": "Set New Password",
        "ENTER_NEW_PASSWORD": "Enter New Password",
        "CONFIRM_NEW_PASSWORD": "Confirm New Password",
        "PASSWORD_VALIDATION": "At least 8 characters, one capital letter, one number and one special character"
      }
    },
    "ERROR_PAGE": {
      "TITLE": "Page Not Found",
      "TITLE_500": "Internal server error",
      "TITLE_ACCESS_DENIED": "Access Denied",
      "TITLE_SOMETHING_WENT_WRONG": "Something went wrong",
      "TITLE_INTERNAL_SERVER": "Internal server error",
      "DESCRIPTION": "The link you followed may be broken or the page may have been removed.",
      "DESCRIPTION_500": "There wan an error. Please try agin later.",
      "DESCRIPTION_ACCESS_DENIED": "You do not have permission to view this page.",
      "DESCRIPTION_SOMETHING_WENT_WRONG": "Looks like something went wrong. We're working on it",
      "BACK_TO_HOMEPAGE": "Back To Homepage"
    }
  },
  "COMMON": {
    "TEXT": {
      "URBAN": "Urban",
      "INTERCITY": "Intercity",
      "ACTIVE": "Active",
      "INACTIVE": "Inactive",
      "DRAFT": "Draft",
      "PUBLISHED": "Published",
      "PENDING": "Pending",
      "IN_PROGRESS": "In Progress",
      "SENT": "Sent",
      "SCHEDULED": "Scheduled",
      "SETTLED": "Settled",
      "UNSETTLED": "Unsettled",
      "DAMAGED": "Damaged",
      "OBSOLETE": "Obsolete",
      "INREPAIR": "In-Repair",
      "DONE": "Done",
      "ANONYMOUS": "Anonymous",
      "BLACKLISTED": "Blacklisted",
      "NEW": "New",
      "ASSIGNED": "Assigned",
      "NOT_ASSIGNED": "Not Assigned",
      "READY": "Ready",
      "CANCELLED": "Cancelled",
      "SEARCH": "Search",
      "DATE": "Date",
      "DEPARTURE_TIME": "Departure Time",
      "START_DATE": "Start Date",
      "END_DATE": "End Date",
      "START_TIME": "Start Time",
      "END_TIME": "End Time",
      "SELECT_START_TIME": "Select Start Time",
      "SELECT_END_TIME": "Select End Time",
      "ADD_CUSTOM_TIME": "Add Custom Time 00:00",
      "ENDS_ON": "Ends On",
      "SELECT_DATE": "Select Date",
      "NEVER_ENDS": "Never Ends",
      "OUT_OF_SERVICE": "Out Of Service",
      "OUTOFSERVICE": "Out Of Service",
      "MAINTENANCE": "Maintenance",
      "DURATION": "Duration",
      "MONDAY": "Monday",
      "TUESDAY": "Tuesday",
      "WEDNESDAY": "Wednesday",
      "THURSDAY": "Thursday",
      "FRIDAY": "Friday",
      "SATURDAY": "Saturday",
      "SUNDAY": "Sunday",
      "SECONDS": "Seconds",
      "VALID_HOURS": "Please enter valid hours (0-23) and minutes (0-59)",
      "TIME_FORMAT": "Please add time in this format: 00:00",
      "OCCURRENCE": "Occurrence",
      "NO_TRIPS_ADDED": "No trips added",
      "STATUS": "Status",
      "LINE": "Line",
      "DATE_FROM": "Date From",
      "DATE_TO": "Date To",
      "BASED_ON": "Based On",
      "LOCATIONS": "Locations",
      "COUNTRY": "Country",
      "ZONES_AVAILABILITY": "Zones Availability",
      "MODULE": "Module",
      "DIRECTION": "Direction",
      "PASS_CATEGORY": "Pass Category",
      "CITY": "City",
      "ROLE": "Role",
      "VEHICLE": "Vehicle",
      "CONDUCTEUR_RECEVEUR": "Driver / Attendant",
      "TYPE_PLACEHOLDER": "Type",
      "FROM_DATE": "From Date",
      "TO_DATE": "To Date",
      "TYPE": "Type",
      "SELECTED_PATTERNS_OF_SAME_TYPE": "Selected patterns of the same type cannot overlap",
      "REPEATED_WEEKDAY": "on weekdays ",
      "REPEATED_WEEKEND": "on weekends ",
      "ONE_TIME": "one time ",
      "DAILY": "daily ",
      "CUSTOM": "custom ",
      "ON_MONDAY": "on Monday ",
      "ON_TUESDAY": "on Tuesday ",
      "ON_WEDNESDAY": "on Wednesday ",
      "ON_THURSDAY": "on Thursday ",
      "ON_FRIDAY": "on Friday ",
      "ON_SATURDAY": "on Saturday ",
      "ON_SUNDAY": "on Sunday ",
      "AND": " and ",
      "ON": "on"
    },
    "VALIDATION": {
      "NOT_FOUND": "Not Found",
      "REQUIRED": "Required",
      "MIN_LENGTH": " Title must be longer than or equal to 2 characters",
      "INVALID_FORMAT": "Invalid Format",
      "INVALID_DATE_RANGE": "Invalid Date Range",
      "INVALID_PASSWORD_FORMAT": "Incorrect Password Format",
      "USER_ID_VALIDATION": "Employee ID should be 6 to 8 characters",
      "START_END_STOPS_REQUIRED": "Start and end stops are required!",
      "INVALID_STOPS_DATA": "Invalid stops data! Please enter complete data",
      "INVALID_BLOCKS_DATA": "Invalid blocks data! Please enter complete data",
      "INVALID_PATTERNS_DATA": "Invalid patterns data! Please enter complete data",
      "PATTERNS_REQUIRED": "Patterns are required!",
      "INVALID_EMAIL_FORMAT": "Invalid email format",
      "ENTER_REQUIRED_FIELDS": "Please enter the required fields!",
      "MAX_DISCOUNT_VALUE": "Discount value should be between 0 - 100",
      "MAX_CAPACITY_VALUE": "Capacity value should be between 0 - 100",
      "DRIVERS_ERROR": "This driver is already assigned for another shift!",
      "DRIVERS_ERROR_THIS": "This driver is already assigned to this shift.",
      "CONDUCTORS_ERROR": "This attendant is already assigned for another shift!",
      "CONDUCTORS_ERROR_THIS": "This attendant is already assigned to this shift.",
      "COST_VALIDATION": "Maximum cost must be greater than Minimum Cost",
      "60_VALIDATION": "must be less than 60 characters",
      "MAX_VALUE": "The value cannot exceed the maximum limit of {{max}}",
      "MIN_VALUE": "The value cannot be less than the minimum limit of {{ min }}",
      "BUDGET_VALIDATION": "Maximum budget must be greater than Minimum budget",
      "MAX_IMAGE_ALLOWED": "Only 10 images are allowed!"
    }
  },
  "GENERAL": {
    "BUTTONS": {
      "SELECT": "Select",
      "NEW": "New",
      "REFRESH": "Refresh",
      "ANONYMOUS": "Anonymous",
      "BLACKLISTED": "Blacklisted",
      "ASSIGNED": "Assigned",
      "VIEW_ON_MAP": "View On Map",
      "CANCEL": "Cancel",
      "APPLY": "Apply",
      "INSERT": "Insert",
      "CONFIRM": "Confirm",
      "LEAVE_PAGE": "Leave Page",
      "FILTERS": "Filters",
      "ACTIONS": "Actions",
      "EXPIRED": "Expired",
      "UPCOMING": "Upcoming",
      "TODAY": "Today",
      "TOMORROW": "Tomorrow",
      "YESTERDAY": "Yesterday",
      "LAST_7_DAYS": "Last 7 Days",
      "CURRENT": "Current",
      "PAST": "Past",
      "NEXT": "Next",
      "CLOSE": "Close",
      "SUBMIT": "Submit",
      "DELETE": "Delete",
      "SAVE": "Save",
      "UPDATE": "Update",
      "RESET": "Reset",
      "ADD": "Add",
      "EDIT": "Edit",
      "CREATE": "Create",
      "ACTIVATE": "Activate",
      "DEACTIVATE": "Deactivate",
      "PENDING": "Pending",
      "DAMAGED": "Damaged",
      "OBSOLETE": "Obsolete",
      "OUT_OF_SERVICE": "Out Of Service",
      "INREPAIR": "In-Repair",
      "MAINTENANCE": "Maintenance",
      "DUPLICATE": "Duplicate",
      "DRAFT": "Set as Draft",
      "SAVE_DRAFT": "Save as Draft",
      "SAVE_PENDING": "Set as Pending",
      "SAVE_CHANGES": "Save changes",
      "PUBLISH": "Publish",
      "READY": "Ready",
      "MARK_AS_READY": "Mark As Ready",
      "UNASSIGN": "Unassign",
      "SELECT_ALL": "Select All",
      "UNSELECT_ALL": "Unselect All",
      "SAVE_DRAFT_GRAPH": "Save As Draft & View Graph",
      "DOWNLOAD_TEMPLATE": "Download Template",
      "DOWNLOAD_SELECTED_ROWS": "Download Selected Rows",
      "DOWNLOAD_FILTERED_ROWS": "Download Filtered Rows",
      "IMPORT": "Import",
      "EXPORT": "Export",
      "SET_AS_DRAFT": "Set as Draft",
      "SET_AS_PENDING": "Set as Pending",
      "SAVE_CONFIGURE": "Save & Configure Services",
      "VIEW_ALL_ZONES": "View All Zones"
    }
  },
  "MSGS": {
    "GENERAL": {
      "INVALID_PHONE_NUMBER": "Invalid phone number",
      "REQUIRED_EMAIL": "Email is required",
      "SOMETHING_WRONG": "Something went wrong",
      "SELECT_ROW": "Please select at least one row.",
      "SELECT_FILTER": "Please select at least one filter.",
      "CONFIRM_DEPARTURE_TIMES": "Please confirm departure times to proceed",
      "CONFIRM_PUBLISH_SERVICES": "Publishing a Service Creation is irreversible, the Service Creation cannot be changed afterward! New timetables created later within this date range no longer be included.",
      "DRAFT_TIMETABLES-DETECTED": "We detect Draft Timetables within the same date range. By publishing, these timetables and new ones will no longer be included.",
      "INVALID_FILE": "Invalid file type! Only jpg, jpeg, png and pdf are allowed",
      "INVALID_IMAGE": "Invalid image type! Only jpg, jpeg, png is allowed",
      "DONE": "Done",
      "ALL_ZONES": "All Zones",
      "ARE_YOU_SURE": "Are you sure you want to perform this action ?",
      "NO_RESULTS_FOUND": "No Results Found",
      "NO_TRANSACTION_EXISTS": "No transaction was found!",
      "NO_RESULTS_FOUND_GENERIC": "Record does not exist! Click on New or refine your search.",
      "FILE_TOO_LARGE": "The selected file is too large. Please select a file that is less than 200 KB.",
      "IMAGE_TOO_LARGE": "The selected image is too large. Please select an image that is less than 2 MB.",
      "LOADING": "Loading please wait.",
      "START_DATE_ERROR": "Date from must be earlier than date to.",
      "START_TIME_ERROR": "Start time must be earlier than end time.",
      "END_TIME_ERROR": "End time must be later than start time.",
      "INVALID_END_TIME": "Invalid end time",
      "START_END_TIME_SAME": "Start time and end time cannot be the same",
      "YES": "YES",
      "NO": "NO",
      "SEGMENT": "Segment",
      "STOP": "Stop",
      "UNSAVED_CHANGES": "Are you sure you want to leave the screen without saving the changes?",
      "DATA_LOST": "This action may result in the loss of unsaved data. Are you sure you want to proceed?",
      "CONFIRMATION_PROFILE": "Are you sure you want to submit your Profile?",
      "DELETE_JOB_CONFIRMATION": "Are you sure you want to delete this Job?",
      "DELETE_JOB_SUCCESS": "Job Deleted Successfully"
    },
    "USERS": {
      "EDIT_SUPER_ADMIN": "Super Admin cannot be edited",
      "DELETE_TEXT": "Are you sure you want to delete this user?",
      "CREATE_TEXT": "User created successfully",
      "SAVE_AS_DRAFT_TEXT": "User saved as pending successfully",
      "UPDATE_TEXT": "User detail updated successfully",
      "UNABLE_TO_UPDATE": "Unable to update user",
      "DELETE_TEXT_SUCCESS": "User deleted successfully",
      "UNABLE_TO_DELETE": "Unable to delete user",
      "UPDATE_TEXT_STATUS": "The Selected user(s) status updated successfully",
      "UNABLE_TO_UPDATE_TEXT_STATUS": "Unable to update user status",
      "UPDATE_PASSWORD": "User password updated successfully",
      "UNABLE_TO_UPDATE_PASSWORD": "Unable to update user password",
      "DELETE_USER": "Are you sure you want to delete this user?",
      "CREATE_USER": "User created successfully",
      "SAVE_AS_DRAFT_USER": "User saved as draft successfully",
      "UPDATE_USER_PROFILE": "User profile updated successfully",
      "UPDATE_PROFILE": "Profile Updated Successfully! Please refresh the page or log out and log back in to view the changes.",
      "UNABLE_TO_UPDATE_PROFILE": "Unable to update user profile",
      "UPDATE_USER_ADDRESS": "User address updated successfully",
      "UNABLE_TO_UPDATE_ADDRESS": "Unable to update user address",
      "UPDATE_USER_DETAILS": "User detail updated successfully",
      "UNABLE_TO_UPDATE_DETAILS": "Unable to update user details",
      "UPDATE_USER_DOCUMENTS": "User document updated successfully",
      "UPDATE_UPLOAD_USER_DOCUMENTS": "User documents updated and uploaded successfully",
      "UNABLE_TO_UPDATE_DOCUMENTS": "Unable to update user documents",
      "UNABLE_TO_CREATE": "Unable to create user",
      "DELETE_DOCUMENT_SUCCESS": "Document deleted successfully",
      "UNABLE_TO_DELETE_DOCUMENT": "Unable to delete document",
      "DOWNLOAD_DOCUMENT_SUCCESS": "Document downloaded successfully",
      "UNABLE_TO_DOWNLOAD_DOCUMENT": "Unable to download document",
      "DELETE_USER_SUCCESS": "User deleted successfully",
      "UPDATE_USER_STATUS": "The Selected user(s) status updated successfully",
      "UNABLE_TO_UPDATE_USER_STATUS": "Unable to update user status",
      "UNABLE_TO_DOWNLOAD_TEMPLATE": "Unable to download template",
      "UNABLE_TO_EXPORT": "Unable to export users",
      "UNABLE_TO_EXPORT_NFC": "Unable to export NFC cards",
      "ONLY_EXCEL_FILE_ERROR": "Invalid file type! Only .xls and .xlsx are allowed",
      "ONLY_CSV_FILE_ERROR": "Invalid file type! Only .csv is allowed",
      "SELECT_FILE": "Please choose a file.",
      "IMPORT_USERS": "The import process has started. You will be notified via email upon its completion.",
      "UNABLE_TO_IMPORT": "The import process could not be started. Revise the submitted file",
      "UNABLE_TO_DELETE_SUPER_ADMIN": "One or more selected users have the 'super_admin' role. Users with 'super_admin' roles cannot be deleted.",
      "OWN_PROFILE_DELETION": "You cannot deactivate or delete your user profile",
      "USER_HAS_NO_ACTIVITIES": "The user has no activities",
      "SUPER_ADMIN_STATUS_CHANGE": "User of this role cannot change status of Super Admin"
    },
    "PORTFOLIO": {
      "PROFILE_CREATED": "Profile reated successfully"
    }
  },
  "BREADCRUMBS": {
    "USER_MANAGEMENT": {
      "TITLE": "User Management",
      "USER_LISTING": "Users",
      "ADMIN_USER": "Admin User",
      "APP_USER": "App User",
      "ADD_USER": "Add User",
      "EDIT_USER": "Edit User"
    },
    "DASHBOARD": {
      "TITLE": "Dashboard"
    },
    "PROFILE": {
      "TITLE": "Profile"
    },
    "SYSTEM_LOGS": {
      "TITLE": "System Logs"
    }
  },
  "PAGINATION": {
    "SHOWING": "Showing",
    "TO": "to",
    "OF": "of",
    "ENTERIES": "entries"
  },
  "MENU": {
    "Main": "Main",
    "DASHBOARD": "Dashboard",
    "MODULE": "MODULE",
    "ADMIN_USERS": "Admin Users",
    "APP_USERS": "App Users",
    "ORDERS": "Orders",
    "RETURNS": "Returns",
    "PAYMENTS_METHODS": "Payments Methods",
    "DELIVERY_METHODS": "Delivery Methods",
    "PRODUCTS": "Products",
    "CATEGORIES": "Categories",
    "PAGES": "Pages",
    "SETTINGS": "Settings",
    "USER_MANAGEMENT": "User Management",
    "SYSTEM_SETTINGS": "System Settings"

  },
  "TABLE": {
    "BASED_ON": "Based On",
    "PERMISSIONS": "Permissions"
  },
  "CONSTANTS": {
    "DEACTIVATED": "Deactivated",
    "DELETED": "Deleted",
    "ADMIN_USER": "Admin User",
    "APP_USER": "App User",
    "STOP": "Stop",
    "STATION": "Station",
    "SEGMENT": "Segment",
    "URBAN": "Urban",
    "INTERCITY": "Intercity",
    "SINGLE": "Single",
    "BULK": "Bulk",
    "IN": "In",
    "OUT": "Out",
    "ONBOARD": "On-Board",
    "HANDHELD": "Hand-Held",
    "COMMERCIAL": "Commercial",
    "POINT_OF_SALES": "Point Of Sales",
    "FX925": "FX925 Validator",
    "FX205": "FX205 Mobile/Android",
    "FX408": "FX408 Tablet/Android",
    "INNER_HANOVER_DISPLAY": "Inner Hanover Display",
    "OUTER_HANOVER_DISPLAY": "Outer Hanover Display",
    "LAPTOP": "Laptop",
    "CAMERA": "Camera",
    "THERMAL_PRINTER": "Thermal Printer",
    "CARD_PRINTER": "Card Printer",
    "FREE": "Free Pass",
    "SPECIAL": "Special Pass",
    "TIME_BASED": "Time-Based Pass",
    "NORMAL_PRIORITY": "Normal Priority",
    "SYSTEM": "System",
    "HIGH_PRIORITY": "High Priority",
    "ONE_TIME": "One Time",
    "REPEATED_WEEKDAY": "Repeat Weekdays",
    "REPEATED_WEEKEND": "Repeat Weekends",
    "DAILY": "Daily",
    "CUSTOM": "Custom",
    "STATUS": {
      "ACTIVE": "Active",
      "INACTIVE": "Inactive",
      "DRAFT": "Draft",
      "PENDING": "Pending",
      "PUBLISHED": "Published",
      "IN_PROGRESS": "In Progress",
      "READY": "Ready",
      "CANCELLED": "Cancelled",
      "DONE": "Done",
      "OUTOFSERVICE": "Out Of Service",
      "MAINTENANCE": "Maintenance",
      "SETTLED": "Settled",
      "UNSETTLED": "Unsettled",
      "SENT": "Sent"
    },
    "ROLE": {
      "DRIVER": "Driver",
      "ATTENDANT": "Attendant"
    }
  }
}
