// src/app/state/categories/categories.module.ts
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { categoriesReducer } from './store/categories.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('categories', categoriesReducer),
  ],
})
export class CategoriesModule {}
