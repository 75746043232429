import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as en from '../assets/i18n/en.json';
import * as fr from '../assets/i18n/fr.json';
import { AuthService, SharedDataService } from './core/services';
import { destroy } from './core/shared/helpers/destroy';

import { Title } from '@angular/platform-browser';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterOutlet, LoadingBarRouterModule],

})
export class AppComponent implements OnInit {
  isOffline: boolean = false;

  @HostListener('window:online', ['$event'])
  onOnline() {
    this.isOffline = false;
  }

  @HostListener('window:offline', ['$event'])
  onOffline() {
    this.isOffline = true;
  }

  private readonly destroy$ = destroy();
  public title = 'sid-customer-frontend';
  /**
   * Initializes a new instance of the class.
   *
   * @param {any} document - The document object.
   * @param {Object} platformId - The platform ID.
   * @param {Title} titleService - The title service.
   * @param {TranslateService} translate - The translation service.
   * @param {SharedDataService} sharedDataService - The shared data service.
   */
  constructor(
    @Inject(DOCUMENT) private readonly document: any,
    private readonly titleService: Title,
    private readonly translate: TranslateService,
    public sharedDataService: SharedDataService,
    public authService: AuthService,
  ) {
    this.translate.setTranslation('en', en, true);
    this.translate.setTranslation('fr', fr, true);
    sharedDataService.appConfig$.pipe(this.destroy$()).subscribe({
      next: appConfig => {
        const lang =
          this.sharedDataService.localService.get('language', false)?.code?.toLowerCase() || 'en';
        const code = lang || 'en';
        if (code != this.translate.currentLang?.toLowerCase()) {
          this.translate.setDefaultLang(code);
          this.translate.use(code);
        }
        if (this.document.documentElement.lang?.toLowerCase() !== code) {
          this.document.documentElement.lang = code;
        }
      },
    });
    this.sharedDataService.dataModified.next({ component: 'base', isModified: false, data: null });
  }

  /**
   * Initializes the component and sets the page title based on the translation of 'PAGE_TITLE'.
   * Loads the Google Maps API if the platform is a browser and sets the map key based on the application configuration.
   */
  ngOnInit() {
    this.titleService.setTitle(this.title);
    if(this.authService.isBrowser){
      this.isOffline = !navigator.onLine;
    }
    const token = this.authService.getToken();
    if(token){
      this.authService.setTokenExpiryTimeOut(token);
    }
    if (this.authService.isTokenExist() && this.authService.isTokenExpired()) {
      this.authService.logout();
    }
  }
}
