import { RegisterResponse } from "../core/interfaces/register_response";
import { User } from "../types/auth.type";

export interface AuthState {
  user: User | null;
  token: string | null;
  loading: boolean;
  error: string | null;
  tokenVerified: boolean;
  registerResponse: RegisterResponse | null;
}

export const initialAuthState: AuthState = {
  user: null,
  token: null,
  loading: false,
  error: null,
  tokenVerified: false,
  registerResponse: null
};
