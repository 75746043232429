import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { inject } from '@angular/core';
import {
  DEFAULT_INVALID_TOKEN_SERVER_RESPONSE,
  DEFAULT_INVALID_TOKEN_SIGNATURE_SERVER_RESPONSE
} from '@src/app/core/constants/system.constant';
import { AuthService } from '@src/app/core/services/auth.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LocalStorageService } from '../services';

export const jwtInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const localStorageService = inject(LocalStorageService);
  const authService = inject(AuthService);
  request = request.clone({
    setHeaders: {
      'x-locale': '',
    },
  });
  // Checking token only for Auth apis
  const url: string = request.url.toLowerCase();
  const isTokenRequired = !url.includes('/login') && !url.includes('amazonaws.com'); 

  if (isTokenRequired) {
    const token = localStorageService.getToken();
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  }

  return next(request).pipe(
    tap({
      next: (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // if any response says, token not provided
          if (
            !isTokenRequired &&
            event.body &&
            (event.body.message === DEFAULT_INVALID_TOKEN_SERVER_RESPONSE ||
              event.body.message === DEFAULT_INVALID_TOKEN_SIGNATURE_SERVER_RESPONSE ||
              event.status == 401 ||
              event?.body?.status == 401)
          ) {
            // ....
            if (localStorageService.getToken(true)) {
              authService.resetToken();
            }
          }
        }
      },
      error: (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (!isTokenRequired && err.status == 401) {
            if (localStorageService.getToken(true)) {
              authService.resetToken();
            }
          }
        }
      },
    }),
  );
};
