import { createReducer, on } from "@ngrx/store";
import { getProfessionals, getProfessionalsFailure, getProfessionalsSuccess } from "./professionals.action";
import { initialState } from "./professionals.state";

export const professionalsReducer = createReducer(
  initialState,
  on(getProfessionals, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(getProfessionalsSuccess, (state, { professionals }) => ({
    ...state,
    professionals,
    loading: false,
  })),
  on(getProfessionalsFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),
);
