import { ListDetails } from "../../types/option.type";
import { Professional } from "../../types/professionals.type";

export interface ProfessionalsState {
  professionals: ListDetails<Professional> | null;
  loading: boolean;
  error: any;
}

export const initialState: ProfessionalsState = {
  professionals: null,
  loading: false,
  error: null,
};