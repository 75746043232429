import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  BrowserModule,
  provideClientHydration,
  withHttpTransferCacheOptions,
} from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AuthGuard } from '@src/app/core/guards';
import { baseUrlInterceptorFn } from '@src/app/core/interceptors/base.url.interceptor';
import { AuthService, BaseNetworkService, HttpService } from '@src/app/core/services';
import { LayoutModule } from '@src/app/features/layout/layout.module';
import { environment } from '@src/environments/environment';
import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { MarkdownModule } from 'ngx-markdown';
import { AppRoutingModule, routes } from './app-routing.module';
import { ApiModule, Configuration, ConfigurationParameters } from './core/api';
import { ROOT_LEVEL_MODULES } from './core/constants';
import { jwtInterceptorFn } from './core/interceptors';
import { LocalStorageService, TranslationLoaderService } from './core/services';

import { AuthModule } from './features/auth/auth.module';
import { CategoriesModule } from './features/landing-page/categories.module';

const apiConfigFactory = () => {
  const params: ConfigurationParameters = {
    basePath: '',
    withCredentials: true,
  };
  return new Configuration(params);
};

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          typescript: () => import('highlight.js/lib/languages/typescript'),
          scss: () => import('highlight.js/lib/languages/scss'),
        },
      },
    },
    LocalStorageService,
    BaseNetworkService,
    HttpService,
    AuthService,
    TranslationLoaderService,
    AuthGuard,
    provideRouter(routes),
    importProvidersFrom(
      LayoutModule,
      ...ROOT_LEVEL_MODULES,
      BrowserModule,
      AppRoutingModule,
      ApiModule.forRoot(apiConfigFactory),
      AuthModule,
      CategoriesModule,
      StoreModule.forRoot([], {}),
      StoreDevtoolsModule.instrument({
        maxAge: 25,
        logOnly: environment.production,
      }),
      EffectsModule.forRoot([]),
      MarkdownModule.forRoot(),
    ),
    provideClientHydration(
      withHttpTransferCacheOptions({
        includePostRequests: true,
      }),
    ),
    provideAnimations(),
    provideHttpClient(withInterceptors([jwtInterceptorFn, baseUrlInterceptorFn])),
  ],
};
