import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of } from "rxjs";
import { ProfessionalListingRepository } from "../../repositories/professional-listing.repository";
import { getProfessionals, getProfessionalsFailure, getProfessionalsSuccess } from "./professionals.action";

@Injectable()
export class ProfessionalsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly professionalListingRepository: ProfessionalListingRepository,
) {}

  getProfessionals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProfessionals),
      mergeMap(({ params }) =>
        this.professionalListingRepository.getProfessionals(params).pipe(
          map((response) => getProfessionalsSuccess({ professionals: response })),
          catchError((error) => of(getProfessionalsFailure({ error: error.error.message })))
        )
      )
    )
  );
}